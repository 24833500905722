<template>
  <div class="product-page--create">
    <v-row no-gutters>
      <v-col cols="12">
        <redirect class="mb-5" title="Upsell offers" route="website.apps.upsell.list" />
        <div class="page-header">
          <h2 class="page-title">Update Offer</h2>
        </div>
      </v-col>
    </v-row>
    <v-col cols="12">
      <Notification type="error" :messages="errors" v-if="errors.length > 0" />
    </v-col>
    <OfferSummary />
    <v-row no-gutters>
      <v-col cols="12" sm="4">
        <h4>Choose offer types</h4>
      </v-col>
      <ListType :screenType="'update'" />
    </v-row>
    <v-col cols="12" class="my-3">
      <v-divider class=""></v-divider>
    </v-col>
    <PrePurchase v-if="idActiveType === 1" />
    <InCart v-if="idActiveType === 2" />
    <PostPurchase v-if="idActiveType === 3 && !isLoading" :type="'update'" :defaultData="formData" />
    <v-col cols="12" class="my-3">
      <v-divider class=""></v-divider>
    </v-col>
    <v-col cols="12" class="d-flex" v-if="idActiveType !== 3">
      <v-spacer></v-spacer>
      <v-btn outlined class="mr-3" @click="onCancel">Discard</v-btn>
      <v-btn :loading="isLoading" color="primary" @click="onSubmit">Save</v-btn>
    </v-col>
    <BeforeLeavePage />
  </div>
</template>

<script>
import utilities from '@/helpers/utilities';
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';
import resourceBeforeLeavePage from '@/mixins/before-change-router';
import { collectionApi } from '@/apis/collection';
import PrePurchase from './components/PrePurchase';
import InCart from './components/InCart';
import PostPurchase from './components/PostPurchase';
import OfferSummary from './components/OfferSummary';
import { offerApi } from '@/apis/offers';
import { convertIdsToArrays } from '@/helpers/convertData';

import ListType from './components/ListType';

export default {
  // mixins: [resourceBeforeLeavePage],
  components: {
    ListType,
    PrePurchase,
    InCart,
    PostPurchase,
    OfferSummary,
  },
  data() {
    let idActiveType = parseInt(this.$route?.query?.tab) || 1;
    return {
      componentMount: false,
      errors: [],
      idActiveType: idActiveType,
      configSelectionItem: {
        type: 'products',
        model: 'targetIds',
        data: [],
      },
      dialog: false,
      isLoading: false,
      typeTarget: 'all',
      itemsMessage: [
        {
          name: 'Frequently bought with {{product name}}',
          id: 'Frequently bought with {{product name}}',
        },
        {
          name: 'Complete your order with {{product name}}',
          id: 'Complete your order with {{product name}}',
        },
        {
          name: 'Best deal today for lucky customers like you',
          id: 'Best deal today for lucky customers like you',
        },
      ],
      dataDefault: {
        collectionRecommendIds: [],
        collectionTargetIds: [],
        productRecommendIds: [],
        productTargetIds: [],
      },
      defaultFormData: {},
      formData: {
        sameProductType: null,
        activated: true,
        offerMessage: 'Complete your order with {{product name}}',
        offerType: 'prePurchase',
        priority: 0,
        offerName: '',
        offerTitle: null,
        recommendIds: [], // product collection ids ,
        recommendType: null, //product, collection, sameCollection, relevantCollection
        targetIds: [],
        targetType: null, //all, product, collection
        store: '',
        enableDiscount: true,
        percentageDiscount: 0,
        discountData: [],
        countries: null,
        countdownTime: 5,
        isFreeShipping: true,
        shippingRate: 0,
        viewInOrder: true,
      },
    };
  },
  validations: {
    formData: {
      offerName: {
        required,
      },
    },
  },
  async created() {
    if (this.$route.params.id && !this.componentMount) {
      try {
        this.isLoading = true;
        let res = (await offerApi.getById(this.$route.params.id)).data.offer;
        let [collectionRecommendIds, collectionTargetIds, productRecommendIds, productTargetIds] = await Promise.all([
          convertIdsToArrays('collection', res.collectionRecommendIds),
          convertIdsToArrays('collection', res.collectionTargetIds),
          convertIdsToArrays('product', res.productRecommendIds),
          convertIdsToArrays('product', res.productTargetIds),
        ]);
        this.dataDefault = {
          collectionRecommendIds,
          collectionTargetIds,
          productRecommendIds,
          productTargetIds,
        };

        this.formData = {
          activated: res.activated,
          sameProductType: res.sameProductType,
          offerMessage: res.offerMessage,
          offerType: res.offerType,
          priority: res.priority,
          offerName: res.offerName,
          offerTitle: res.offerTitle,
          recommendIds: [], // product collection ids ,
          recommendType: res.recommendType, //product, collection, sameCollection, relevantCollection
          targetIds: [],
          targetType: res.targetType, //all, product, collection
          enableDiscount: res.enableDiscount,
          percentageDiscount: res.percentageDiscount,
          discountData: res.discountData,
          countries: res.countries,
          countdownTime: res.countdownTime,
          isFreeShipping: res.isFreeShipping,
          shippingRate: res.shippingRate,
          viewInOrder: res.viewInOrder,
        };
        this.defaultFormData = Object.assign({}, res);
        if (this.formData.recommendType === 'product' || this.formData.recommendType === 'collection') {
          this.formData.recommendIds = this.dataDefault[`${this.formData.recommendType}RecommendIds`];
          this.dataDefault[`${this.formData.recommendType}RecommendIds`] = this.dataDefault[
            `${this.formData.recommendType}RecommendIds`
          ];
        }
        if (this.formData.targetType === 'product' || this.formData.targetType === 'collection') {
          this.formData.targetIds = this.dataDefault[`${this.formData.targetType}TargetIds`];
          this.dataDefault[`${this.formData.targetType}TargetIds`] = this.dataDefault[
            `${this.formData.targetType}TargetIds`
          ];
        }
        this.isLoading = false;
        this.$forceUpdate();
      } catch (error) {
        //empty
      }
      this.componentMount = true;
    }
  },
  methods: {
    onCancel() {
      this.$v.$reset();

      this.$forceUpdate();
      //website.collections
    },
    checkValidate() {
      let [targetType, recommendType, targetIds, recommendIds] = [
        this.formData.targetType,
        this.formData.recommendType,
        this.formData.targetIds,
        this.formData.recommendIds,
      ];
      if (
        targetType === 'all' &&
        (recommendType === 'sameCollection' ||
          recommendType === 'relevantCollection' ||
          recommendType === 'sameNiche' ||
          recommendType === 'sameTitle')
      ) {
        return true;
      }
      if (
        (targetType === 'all' || (targetType !== 'all' && targetIds.length > 0)) &&
        (recommendType === 'sameCollection' ||
          recommendType === 'relevantCollection' ||
          recommendType === 'sameNiche' ||
          recommendType === 'sameTitle' ||
          ((recommendType !== 'sameCollection' ||
            recommendType !== 'relevantCollection' ||
            recommendType !== 'sameNiche' ||
            recommendType !== 'sameTitle') &&
            recommendIds.length > 0))
      ) {
        return true;
      } else return false;
    },
    async sendResquest() {
      let convertData = Object.assign({}, this.formData);
      convertData.targetIds = convertData.targetIds.map(item => item._id);
      convertData.recommendIds = convertData.recommendIds.map(item => item._id);
      convertData.store = this.$store.state.auth.accountInfo.storeId;
      if (this.formData.targetType === 'product' || this.formData.targetType === 'collection') {
        convertData[`${this.formData.targetType}TargetIds`] = convertData.targetIds;
      }
      if (this.formData.recommendType === 'product' || this.formData.recommendType === 'collection') {
        convertData[`${this.formData.recommendType}RecommendIds`] = convertData.recommendIds;
      }
      delete convertData.recommendIds;
      delete convertData.targetIds;
      try {
        await offerApi.update(this.$route.params.id, convertData);
      } catch (error) {}
    },
    async onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid && this.checkValidate()) {
        this.sendResquest();
      }
    },
    configDialogSelection(data) {
      this.dialog = true;
      this.configSelectionItem = Object.assign({}, data);
      if (this.formData.targetIds && data.model === 'targetIds') {
        this.configSelectionItem.data = this.formData.targetIds;
      }
      if (this.formData.recommendIds && data.model === 'recommendIds') {
        this.configSelectionItem.data = this.formData.recommendIds;
      }
      // this.configSelectionItem.data = this.dataDefault[
      //   `${data.type.replace('s', '')}${data.model.charAt(0).toUpperCase() + data.model.slice(1)}`
      // ];
    },
    getDataSelected(data, model) {
      this.formData[model] = JSON.parse(JSON.stringify(data));
    },
  },
  mounted() {},
  watch: {
    'formData.targetType': function(n, o) {
      if (o) {
        this.formData.targetIds = [];
      }
      if (
        (o && this.$route.params.id && this.formData.targetType === 'collection') ||
        this.formData.targetType === 'product'
      ) {
        this.formData.targetIds = this.dataDefault[`${this.formData.targetType}TargetIds`];
      }
    },
    'formData.recommendType': function(n, o) {
      if (o) {
        this.formData.recommendIds = [];
      }
      if (
        (o && this.$route.params.id && this.formData.recommendType === 'collection') ||
        this.formData.recommendType === 'product'
      ) {
        this.formData.recommendIds = this.dataDefault[`${this.formData.recommendType}RecommendIds`];
      }
    },
  },
};
</script>
<style lang="scss">
.product-page--create {
  .btn-link {
    color: $main-color;
    &:hover {
      text-decoration: none;
    }
  }
  .v-expansion-panel:before {
    box-shadow: none;
  }
  .v-expansion-panel-header__icon {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
</style>
