<template>
  <v-card class="mx-auto mb-4" outlined>
    <!-- <v-list-item three-line>
      <v-list-item-content>
        <div class="d-flex text-center flex-row justify-space-between">
          <b>Summary</b>
          <v-chip color="success" small outlined>Active</v-chip>
        </div>

        <v-list-item-title class="mb-1">
          Performance
        </v-list-item-title>
        <div class="d-flex flex-row justify-start">
          <div class="mr-4">Sales: 50</div>
          <v-divider vertical></v-divider>
          <div class="mr-4 ml-4">Views: 3</div>
          <v-divider vertical></v-divider>
          <div class="mr-4 ml-4">Add to cart: 1</div>
          <v-divider vertical></v-divider>
          <div class="mr-4 ml-4">Converion rate: 0%</div>
          <v-divider vertical></v-divider>
          <div class="mr-4 ml-4">Checkout success: 0</div>
        </div>
      </v-list-item-content>
    </v-list-item> -->
  </v-card>
</template>

<script>
export default {};
</script>

<style></style>
